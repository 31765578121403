/*Company Page Styles*/
$primary-dark-theme: #38424b !default;
$secondary-dark-theme: lighten($primary-dark-theme, 3%);
$dark-theme-text: #d3d3d3 !default;

.company.dialog.header {
  background-color: #2d353c;
}
.company-dialog-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  color: #495057;
  background-color: $secondary-dark-theme;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  align-items: center;
  justify-content: center;
}
.company.dialog.button {
  background-color: $primary-dark-theme;
}
.company-dialog-header {
  background-color: $primary-dark-theme;
}
.company-dialog-body {
  background-color: $secondary-dark-theme;
}
.company-dialog-group {
  background-color: $primary-dark-theme;
  width: 40px;
}
.mt10 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.company-dialog-button {
  width: 174px;
  text-transform: none !important;
}
.mr20 {
  margin-right: 20px;
}
.company-dialog-group-button {
  max-width: 10px;
  border-radius: 0px;
}
.company-header-dialog-close {
  background-color: green;
  position: absolute;
}
.company-dialog-container {
  max-width: 900px;
  background-color: green;
}
.company-dialog {
  margin-left: 20px;
  margin-right: 20px;
  background-color: green;
}
.floatingLabelFocusStyle {
  color: red;
}
.company-dialog-search-box {
  margin-left: 1rem;
  margin-right: 1rem;
}
.company-addnew-text-placeholder {
  position: absolute;
  color: #6d7784;
  padding-left: 15px;
  height: 3.5rem;
  width: 27rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}
.company-dialog-main {
  overflow: hidden;
}
.company-dialog-center {
  max-width: fit-content;
  max-height: fit-content;
}
.company-dialog-addnew-search {
  //z-index: 1302 !important;
  // background-color: $app-primary !important;
  color: $white !important;
  margin-left: 10px;
  margin-right: 10px;
  .ellipse-shape {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    overflow: hidden;
    height: 100%;
    width: 560px;

    &:after {
      content: "";
      display: block;
      width: 870px;
      position: absolute;
      top: -20px;
      right: -300px;
      height: 280px;
      z-index: 1;
      background-color: rgba($white, 0.06);
      @include border-radius(50% 0 0 50%);
    }
  }

  & .search-bar input {
    @include transition($transition-base);
    background-color: rgba($white, 0.2);
    color: $white;

    & + .search-icon {
      top: 0;

      & i {
        color: rgba($white, 0.8);
      }
    }

    &:focus {
      background-color: $white;
      color: $body-color;

      + .search-icon {
        & i {
          color: rgba($body-color, 0.8);
        }
      }
    }
  }
}
.company-list-search {
  width: 32rem;
  color: $white !important;
  margin-left: 10px;
  .ellipse-shape {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    overflow: hidden;
    height: 100%;
    width: 560px;

    &:after {
      content: "";
      display: block;
      width: 870px;
      position: absolute;
      top: -20px;
      right: -300px;
      height: 280px;
      z-index: 1;
      background-color: rgba($white, 0.06);
      @include border-radius(50% 0 0 50%);
    }
  }

  & .search-bar input {
    @include transition($transition-base);
    background-color: rgba($white, 0.2);
    color: $white;

    & + .search-icon {
      top: 0;

      & i {
        color: rgba($white, 0.8);
      }
    }

    &:focus {
      background-color: $white;
      color: $body-color;

      + .search-icon {
        & i {
          color: rgba($body-color, 0.8);
        }
      }
    }
  }
}

.app-company-wrapper {
  padding-left: 10px;
  width: 100%;
  @media screen and (max-width: 575px) {
    padding: 0px;
  }
}

.app-company-wrapper-detail {
  // padding-left: 30px;
  // padding-right: 20px;
  // padding-top: 20px;
  width: 100%;
  @media screen and (max-width: 575px) {
    padding: 0px;
  }
}
.company-tab-body {
  background-color: #434f5a;
}
.company-table-column-name {
  color: "#00BCD4";
}
.company-detail-text-balance {
  font-size: 1rem;
  // color:'color-primary';
  padding-left: 4px;
}
.company-detail-text-balance-value {
  font-size: 1.3rem;
  font-weight: 500;
  padding-left: 4px;
  padding-bottom: 0.1rem;
}
.company-dialog-input {
  color: red;
}
.paper-button-text {
  text-transform: none;
  font-size: 15px;
}
.company-header-icon {
  font-weight: 900;
  margin-right: 10px;
}
.company-page-header {
  @extend %jr-card-style !optional;
  padding: 0.589rem 1.1765rem;
  background-color: $secondary-dark-theme;
  & .breadcrumb {
    padding: 0 !important;
    background-color: transparent !important;
    margin-bottom: 0;
    font-size: "0.76rem";
    display: flex;
    align-items: center;
    &-item {
      color: $light-gray;
      display: flex;
      align-items: center;
      &.active {
        color: $app-primary;
      }
    }
  }
}
.text-title-add-company {
  font-weight: 400;
  font-family: "Roboto";
  font-size: 1.531rem;
  color: #e0e0e0;
}
.company-page-heading {
  @extend %jr-card-style !optional;
  padding: 32px 0px;
  z-index: 2;
  background-color: $secondary-dark-theme;

  @media screen and (max-width: 575px) {
    padding: 16px 20px;
  }

  & .title {
    text-transform: capitalize;
    font-size: 18px;
  }

  & .breadcrumb {
    padding: 0 !important;
    background-color: transparent !important;
    font-size: 13px;
    display: flex;
    align-items: center;

    &-item {
      color: $light-gray;
      display: flex;
      align-items: center;

      &.active {
        color: $app-primary;
      }
    }
  }
}
.company-table-header-text {
  font-size: 16px;
}
.company-table-header-body {
  font-size: 0.95rem;
  // font-family: 'Lato';
}
.company-add-select {
  &.MuiNativeSelect-root {
    color: "white";
  }
  &.MuiNativeSelect-icon {
    color: "white";
  }
}
.company-border-left {
  color: "green";
  max-height: 1px;
}
.selectStyle {
  margin: 0px !important;
  padding: 0px !important;
}

.css-tlfecz-indicatorContainer {
  padding: 0px !important;
  margin: 0px !important;
}
.MuiSelect-icon {
  top: unset !important;
}
.table-text {
  font-size: 1rem;
  font-weight: 400;
}
