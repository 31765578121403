@import "@protego/sdk/styles/global/_global-dir";
$gutter: rem(26px);
$baseWidth: 1506 - 26;
.PageHeading {
  margin-bottom: rem(30.2px);
}
.Section {
  @extend %jr-card-style !optional;
  padding: rem(30.8px) rem(30px);
  background-color: #434f5a;
}
.SectionCommon > div {
  margin: -$gutter/2;
  display: flex;
  > div {
    padding: $gutter/2;
    &:nth-child(1) {
      flex: 1 1 percentage(394 / $baseWidth);
      display: flex;
      justify-content: center;
    }
    &:nth-child(2) {
      flex: 1 1 percentage(($baseWidth - 394) / $baseWidth);
    }
  }
}
.iconInputPassword {
  visibility: hidden;
}
.buttonSize {
  width: rem(180px);

  &:disabled {
    background-color: rgba(63, 81, 181, 0.8) !important;
  }

  &:hover {
    background-color: #3f51b5 !important;
  }
}
.formWidth {
  width: rem(450px);
  padding-top: rem(40px);
}
@media (min-width: 1366px) {
  .formWidth {
    width: rem(445px);
    padding-top: rem(40px);
  }
}
@media (min-width: 1920px) {
  .formWidth {
    width: rem(420px);
    padding-top: rem(40px);
  }
}
.Link {
  color: #00bcd4 !important;
  cursor: pointer;
  svg {
    margin-top: rem(-6px);
  }
}
.fCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnVerify {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  background-color: #0080ff;
}
.textCode {
  font-weight: 500;
  color: white;
}
.qrCodeBorder {
  display: flex;
  justify-content: center;
  border: 2px solid #dadada;
  border-radius: 5px;
  background-color: white;
}
.icAuthy {
  width: rem(60px);
  height: rem(65px);
}
