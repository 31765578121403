$padding: (20/17) * 1rem;
.Dropdown {
  composes: mdc-elevation--z2 from global;
  &:global(.dropdown-menu) {
    padding: $padding {
      bottom: $padding/2;
    }
  }
}
.Profile {
  min-width: calc(400px - #{$padding * 2});
  > :first-child {
    padding-bottom: $padding;
    border-bottom: 1px solid #556372;
    margin-bottom: $padding/2;
  }
  :global(.MuiAvatar-root) {
    margin-right: $padding;
  }
  :global(.MuiTypography-root) {
    line-height: 1.7;
  }
  :global(.MuiMenuItem-root) {
    padding: {
      top: $padding/2;
      bottom: $padding/2;
      left: 0;
    }
  }
}
