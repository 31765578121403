.statusButton {
  border-radius: 20px;
  padding: 8px 16px;
  display: inline-block;
  color: #ffffff;

  &.waiting {
    background: #ffba54;
  }
  &.success {
    background: #5cce62;
  }
  &.failed {
    background: #fc5c50;
  }
}

.statusWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #414953;
  border-radius: 4px;
  padding: 15px;
}

.timeline {
  color: #a3a7ac;
  margin: 15px 0;
  padding: 0 2px;
}

.actionButton {
  min-width: 175px !important;
  border-radius: 6px !important;
  font-size: 15px !important;
}
