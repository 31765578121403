/*Customer Page Styles*/
$primary-dark-theme: #38424b !default;
$secondary-dark-theme: lighten($primary-dark-theme, 15%);
$dark-theme-text: #d3d3d3 !default;

.customer-header-item {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.customer-header-item1-text {
  color: mediumseagreen;
}
.customer-selected-item {
  color: gray;
}
.customer-header-search {
  width: 400px;
  border-radius: 4px;
  flex-direction: row;
  display: flex;
  background-color: lighten($primary-dark-theme, 15%);
  border-width: 1px;
}
.customer-header-search-icon {
  pointer-events: none;
  display: "flex";
  padding: 5px;
}
.customer-header-search-inputRoot {
  color: "inherit";
}
.customer-header-search-inputInput {
  padding-left: 4px;
}
.customer-list-selected {
  border-radius: 4px;
}
.customer-item-selected {
  background-color: lighten($primary-dark-theme, 15%);
  border-radius: 4px;
  width: 100px;
  text-align: center;
  justify-content: center;
}
.customer-item-selected-text {
  font-weight: 500;
  color: rebeccapurple;
}
.customer-table-text-person {
  color: aqua;
}
.customer-header-text-active {
  color: #38424b;
}
.customer-header-only-line {
  width: 0.5;
  background-color: "white";
  height: 40;
}
.company.dialog.header {
  background-color: white;
  height: 40px;
  width: 100px;
}
