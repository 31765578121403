@import "@protego/sdk/styles/global/_global-dir";

.companyProfileTitle {
  font-size: rem(21px);
}

.companyProfileWapper {
  margin-top: rem(33px);
  margin-left: rem(60px);
}
.companyDetailNavText {
  font-size: 1rem;
  font-weight: 400;
}
.companyDetailNavTextValue {
  font-size: rem(24px);
  font-weight: 400;
  color: #e0e0e0;
}
.companyDetailNavBarVertial {
  padding-left: rem(30px);
  padding-right: rem(30px);
  opacity: 0.5;
}
.downdownBackground {
  background-color: #596977 !important;
  height: rem(37px);
  padding-top: rem(9px) !important;
}
.companyButtonBorder {
  border-radius: 4px !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.searchBoxStyle {
  height: rem(36px);
}
.jrCardPadding {
  padding-left: -10px !important;
  padding-right: -10px !important;
}
.titleAvatar {
  color: #ffffff;
  font-weight: 600;
  font-size: rem(50px);
}
.modalDescription {
  color: #ffffff;
  font-weight: 500;
  font-size: rem(20px);
  padding-top: rem(70px);
  padding-bottom: rem(70px);
  align-items: center;
  display: flex;
  justify-content: center;
}
.tableTextName {
  font-size: 1rem;
  color: #00bcd4;
}
.buttonGroup {
  margin-top: rem(40px);
}
.textFieldStyle {
  margin-bottom: rem(20px);
}
.Link {
  color: #00bcd4 !important;
  cursor: pointer;
  svg {
    margin-top: rem(-6px);
  }
}
.buttonActive {
  background-color: #5cce62;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 20px;
}
.buttonDisable {
  background-color: #4f5f6d;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 20px;
}
.bgSecurity {
  background-color: #38424b;
  border-radius: 6px;
  padding: rem(16px) rem(20px);
  display: flex;
  width: rem(500px);
}
.flexEnd {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
}
.fCenter {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}
.textBold {
  font-weight: 500;
}
