@import "~@protego/sdk/styles/global/_global-dir";

.Card {
  background: #3b454f !important;
  border: #2d353c;
  :global {
    .MuiCardHeader-root {
      height: rem(69px);
    }
    .MuiCardHeader-title {
      font-size: rem(17px);
      font-weight: bold;
    }
    .MuiCardContent-root {
      min-height: 100px;
      background-color: #434f5a;
    }
  }
  .ToggleButton {
    background: #333a43;
    width: rem(40px);
    height: rem(40px);
    border-radius: rem(5px);
  }
}
