.company-list-search {
  margin-right: rem(-7px);
}
.MuiTableCell-root {
  font-weight: 400;
}

.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover,
.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:active,
.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:focus,
.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:visited {
  background-color: rgba(56, 66, 75, 0.6);
}
.MuiSelect-select.MuiSelect-select {
  padding-left: rem(20px);
}
.MuiInputBase-root {
  border-radius: rem(4px);
}
.MuiInputBase-root {
  font-size: 1.6em;
}
.search-bar.right-side-icon .search-icon {
  position: absolute;
  left: rem(10px);
}
