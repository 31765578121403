$primary-dark-theme: #38424b !default;
$secondary-dark-theme: lighten($primary-dark-theme, 3%);
$dark-theme-text: #e0e0e0 !default;

.staff-detail-avatar {
  background-color: $primary-dark-theme;
  padding: 1rem;
  padding-bottom: 2rem;
}
.staff-detail-input {
  margin: 2.353rem;
}
.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}
.flex-row {
  flex: 1;
  flex-direction: row;
  display: flex;
}
.jr-link-padding {
  padding: unset !important;
  margin: unset !important;
}
.backstyle {
  margin-top: -0.6rem;
  padding-left: 0.8rem;
  padding-bottom: 0.8rem;
}
.circleButtonshadow {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  display: inline-block;
}
.circleButton {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  display: inline-block;
}
.arrowback {
  flex: 1;
  display: flex;
  padding-left: 0.8rem;
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.flex-center-start {
  flex: 1;
  align-items: flex-end;
  justify-content: flex-start;
  display: flex;
}
.flex-end {
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
}
.staff-detail-container {
  @extend %jr-card-style !optional;
  padding: 0 !important;
  background-color: $secondary-dark-theme;
  & .breadcrumb {
    padding: 0 !important;
    background-color: transparent !important;
    margin-bottom: 0;
    font-size: 13px;
    display: flex;
    align-items: center;
    &-item {
      color: $light-gray;
      display: flex;
      align-items: center;
      &.active {
        color: $app-primary;
      }
    }
  }
}
.color-picker-palette {
  position: absolute;
  z-index: 1;
  // top:8.8rem;
  // left: 2rem;
}
.color-picker-cover {
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
