@import "@protego/sdk/styles/global/_global-dir";

.formName {
  line-height: 50px;
  border-bottom: 1px solid #fff4;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;

    .name {
      display: flex;
      align-items: baseline;

      .input {
        margin-left: 10px;
        width: 400px;
      }
    }

    .index {
      display: flex;
      align-items: baseline;
      margin-left: 20px;

      .input {
        margin-left: 10px;
        width: 50px;
      }
    }
  }
}

.mainBox {
  display: flex;
  justify-content: space-between;
  user-select: none;
  margin-top: 20px;

  .leftBox {
    width: 50%;
    padding-right: 20px;

    .borderBox {
      min-height: 100%;
      border: 1px solid #99f4;
      border-radius: 5px;
      position: relative;

      .fieldBox {
        display: flex;
        flex-wrap: wrap;
        padding: 20px;

        .field {
          min-height: 40px;
          border: 1px solid #2d8cf0;
          display: flex;
          align-items: center;
          padding: 0 10px;
          margin-right: 20px;
          margin-bottom: 20px;
          cursor: pointer;

          .required {
            color: red;
            margin-right: 5px;
          }

          .text {
            display: flex;
            align-items: center;
            line-height: 16px;
            font-size: 14px;
            color: #2d8cf0;
          }

          .delete {
            cursor: pointer;
            margin-left: 5px;
          }
        }

        .origin {
          border: 1px solid white;
        }

        .target {
          cursor: ew-resize;
        }

        .invalid {
          cursor: not-allowed;
        }
      }

      .hint {
        color: gray;
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
  }

  .rightBox {
    width: 50%;
    min-height: 300px;
    border-left: 1px solid #fff4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;

    .fieldBox {
      display: flex;
      flex-wrap: wrap;

      .field {
        min-height: 40px;
        border: 1px solid #2d8cf0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: crosshair;

        .text {
          line-height: 16px;
          font-size: 14px;
          color: #2d8cf0;
        }

        .edit {
          cursor: pointer;
          margin-left: 5px;
        }
      }

      .selected {
        border-color: gray;
        cursor: not-allowed;

        .text {
          line-height: 16px;
          font-size: 14px;
          color: gray;
        }
      }
    }
  }
}

.addField {
  padding: 40px;

  .field {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .name {
      width: 150px;

      .required {
        color: red;
        margin-right: 5px;
      }
    }

    .readOnly {
      border-radius: 4px;
      background: gray;
    }

    .radioGroup {
      display: flex;
    }
  }

  .info {
    word-wrap: break-word;
    margin-bottom: 20px;
  }
}
