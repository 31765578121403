/*Login Styles*/
/*Login Styles*/

.login-container {
  position: relative;
  height: 100%;
}

.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 30px;
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(4px);
  font-size: 14px;
  max-width: 94%;

  @media screen and (max-width: 575px) {
    padding: 25px 25px 20px;
  }
}
.login-control {
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 500;

  .login-input {
    width: 100%;
    height: 45px;
    border: 1px solid #707070;
    color: #6e777f;
    border-radius: 3px;
    outline: none;
    background: $white;
    font-size: 17px;
    font-weight: 400;
    padding: 0 20px;
  }
}

.login-btn {
  height: 48px;

  span {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
  }
}

.login-header {
  margin-bottom: 30px;

  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
}

.login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.login-content .btn,
.login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;

  & .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}

.app-login-main-content {
  @include display-flex(flex, column);
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(3px);
  overflow: hidden;

  @media screen and (max-width: 524px) {
    width: 100%;
  }
}

.app-login-content {
  padding: 0;
  order: 1;
}

.app-login-header {
  margin-bottom: 30px;
}

.app-login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.app-login-content .btn,
.login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-logo-content {
  padding: 0;
  margin-bottom: 50px;
  text-align: center;

  .logo-lg {
    max-width: 80%;

    img {
      width: 220px;
      max-width: 100%;
      height: auto;
    }
  }
}

.app-social-block {
  @include display-flex();
  @include align-items(center);
  @include justify-content(space-between);

  & .social-link,
  & .social-link li {
    margin: 0;
  }

  & .social-link button {
    border: solid 1px $app-primary;
    @include border-radius(50%);
    color: $app-primary;
    padding: 0;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary;
    }
  }

  & p {
    margin-bottom: 0;
  }
}

.login-form {
  form > :last-child {
    margin-bottom: 0;
  }
}
