@import "@protego/sdk/styles/global/_global-dir";

.companyProfileTitle {
  font-size: rem(21px);
}

.companyProfileWapper {
  margin-top: 1.94rem;
  margin-left: rem(60px);
  margin-right: rem(60px);
}
.companyDetailNavText {
  font-size: 1rem;
  font-weight: 400;
}
.companyDetailNavTextValue {
  font-size: rem(24px);
  font-weight: 400;
  color: #e0e0e0;
}
.companyDetailNavBarVertial {
  padding-left: rem(30px);
  padding-right: rem(30px);
  opacity: 0.5;
}
.downdownBackground {
  background-color: #596977 !important;
  height: rem(37px);
  padding-top: rem(9px) !important;
}
.companyButtonBorder {
  border-radius: 4px !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.searchBoxStyle {
  height: rem(36px);
}
.jrCardPadding {
  padding-left: -10px !important;
  padding-right: -10px !important;
}
.companyBody {
  margin-top: 2rem;
}
.inputMargin {
  margin: 0px !important;
}
.Link {
  color: #00bcd4 !important;
  cursor: pointer;
  svg {
    height: rem(12.38px);
  }
}
.Ids {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  min-width: rem(180px);
}
.widthFileName {
  width: rem(300px) !important;
}
