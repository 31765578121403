/*Faqs Styles*/

.Collapsible {
  background-color: $white;
  margin-bottom: 15px;
}

//The content within the collaspable ChatUserList
.Collapsible__contentInner {
  padding: 20px;
  border: 1px solid $white;
  border-top: 0;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//The link which when clicked opens the collapsable ChatUserList
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  padding: 18px 20px;
  @include border-radius(6px);
  background: theme-color("primary");
  color: $white;

  &:after {
    content: "\f2f9";
    font: {
      family: "Material-Design-Iconic-Font";
      size: 20px;
    }
    position: absolute;
    right: 24px;
    top: 16px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    @include border-radius(6px 6px 0 0);

    &:after {
      transform: rotateZ(180deg);
    }
  }
}

//SideNav
.categories-list {
  margin-bottom: 0;

  & li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & li.active a {
    color: $app-primary;
  }

  & .badge {
    font-size: 9px;
  }
}

.articles-section {
  margin-bottom: 0;

  & .articles {
    margin-bottom: 26px;

    &:last-child {
      margin-bottom: 0;

      & .meta-wrapper {
        margin-bottom: 0;
      }
    }
  }
}

.article-image {
  display: block;
  margin-bottom: 12px;

  & > img {
    @include border-radius(6px);
    width: 100%;
  }
}

.article-description {
  & p {
    font-weight: 500;
    margin-bottom: 2px;
  }
}

.meta-wrapper {
  margin-bottom: 10px;
}

.meta-date,
.meta-comment {
  font-size: 12px;
  color: $light-gray;
  display: inline-block;

  & i {
    display: inline-block;
    margin-right: 6px;
  }
}

.meta-date-light {
  color: #acacac;
}

.meta-date {
  & + .meta-comment {
    margin-left: 10px;
  }
}
