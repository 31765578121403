@import "@protego/sdk/styles/global/_global-dir";

.TabCard {
  background: #3b454f !important;
  border: #2d353c;
}
.tabSanctionedCountries {
  .selectorCountry {
    margin-bottom: 30px;
  }
  .colorTable {
    color: #fff;
  }
}
.textPlaceholder {
  width: rem(143px) !important;
  ::placeholder {
    font-size: 1rem !important;
  }
}
.textInputScore {
  background-color: #545f69 !important;
  width: rem(143px) !important;
  ::placeholder {
    font-size: 1rem !important;
  }
}
.dropdownInput {
  background-color: #545f69 !important;
  width: rem(348px) !important;
  ::placeholder {
    font-size: 1rem !important;
  }
}
.toggleButton {
  background-color: #333a43 !important ;
  width: rem(42px) !important;
  border-radius: 5px !important;
  height: 100%;
}

.itemScore {
  background-color: #3e4953;
  text-decoration: none;
  ::placeholder {
    font-size: 1rem !important;
  }
  padding-top: rem(5px) !important;
  padding-bottom: rem(5px) !important;
  padding-left: rem(4px) !important;
  padding-right: rem(4px) !important;
  border-radius: 5px !important;
  width: rem(100px) !important;
}
.tableContainer {
  margin-top: rem(24px) !important;
}

.pointer {
  cursor: pointer;
}
